/* General box-sizing fixing. */
html {
  //    -moz-box-sizing: border-box;
  //    -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  //    -moz-box-sizing: inherit;
  //    -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
/* Stuff from bootstrap */
html {
  font-size: 62.5%;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

/* End of bootstrap stuff */

/* Fonts */
@import "fonts";

/* Mixins */
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow:    $shadow;
  box-shadow:         $shadow;
}
/* End mixins */

/* MQ */

$mq-breakpoints: (
        smallwidth: 400px,
        minwidth:   625px,
        midwidth:   675px
);

@import "~sass-mq/mq";

/* End MQ */

$colorscheme: green;

$white: null;
$bg-color: null;
$bg-img: null;
$header-img: null;
$footer-color: null;
$text-color: null;
$emphlink-color: null;

@if $colorscheme == grey {
  $white: white;
  $bg-color: #CC6600;
  $bg-img: none;
  $footer-color: rgb(165, 165, 165);
  $text-color: black;
  $emphlink-color: lightblue;
} @else if $colorscheme == beige {
  $white: beige;
  $bg-color: #CC6600;
  $bg-img: url("/images/bookshelf-min.jpg");
  $footer-color: darkkhaki;
  $text-color: #043104;
  $emphlink-color: lightblue;
} @else if $colorscheme == green {
  $color_base: #8B9E52;
  //$color_base--
  $color_secondary1: #757F7C;
  $color_secondary2: #C4D0DC;
  $color_black: #080D0B;
  //
  //  $white: #BCD4D8;
  //  $bg-color: #424D2B;
  $header-img: url("/images/spaceship_wreck_yard.jpg");
  //  $footer-color: darkkhaki;
  //  $text-color: #043104;
  //  $emphlink-color: #A8B893;
  $bg-color: $color_base;
  $white: $color_secondary2;
  $footer-color: $color_secondary1;
  $text-color: $color_black;
  $emphlink-color: $color_secondary1;
}

body {
  background-color: $bg-color;
  @if $bg-img != null {
    background: $bg-color $bg-img repeat-y center center;
    @include mq($until: midwidth) {
      background: $bg-color $bg-img repeat-y center center;
      background-size: contain;
    }
  }
  padding: 10px;
}

body {
  position: relative;
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: $header-img;
    content: "";
    z-index: -1;
    opacity: 0.5;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
}

h1 {
  //  font-family: 'Akashi';
  //  font-family: 'Audiowide';
  //  font-family: 'Iceland';
  font-family: 'Iceland', Helvetica, Arial, sans-serif;
}

.header {
  @if $header-img != null {
    background: $bg-color $header-img no-repeat center center;
    border-radius: 5em;
    padding: 4em;
  }
  .logo {
    width: 100%;
    max-width: 500px;
    background-color: $white;
    @if $header-img != null {
      background-color: fade_out($white, 0.2);
    }
    border-radius: 10px;
    padding: 10px;
    display: inline-block;
    img {
      width: 100%;
    }
  }
  .nav {
    display: inline-block;
    list-style-type: none;
    vertical-align: top;
    a {
      display: block;
      background-color: black;
      margin: 5px;
      padding: 5px;
      color: white;
      text-decoration: inherit;
      border-radius: 5px;
    }
  }
  margin-bottom: 10px;
}


.content {
  @include mq($from: minwidth) {
    display: table-cell;
    /*        width: 75%; */
    width: 65%;
    vertical-align: top;
  }
}

.sidebar {
  @include mq($from: minwidth) {
    display: table-cell;
    padding-left: 15px;
  }
}

.blog {
  padding: 15px;
  border-radius: 10px;
  background-color: $white;
  margin-bottom: 15px;

  h1 {
    margin-top: 0;
    a, a:hover, a:active {
      text-decoration: inherit;
      color: inherit;
    }
  }
}

.next-meeting {
  padding: 15px;
  border-radius: 10px;
  background-color: $white;
  margin: 15px;
  display: inline-block;

  h1 {
    display: inline-block;
    margin: 0;
    padding-right: 1em;
    &::after {
      content: ':';
    }
  }

  p {
    display: inline-block;
  }
}

img.avatar {
  float: left;
  margin-right: 15px;
  border-radius: 50%;
}

.book {
  padding: 15px;
  border-radius: 10px;
  background-color: $white;
  margin-bottom: 15px;
  font-family: 'Audiowide', Helvetica, Arial, sans-serif;

  img {
    max-width: 150px;
    display: block;
    margin: auto;
    @include box-shadow(2px 2px 18px 2px rgba(50, 50, 50, 0.75));
  }

  h1 {
    margin: 0;
    text-decoration: underline;
  }

  h2 { margin: 0; }
  .bookimg{ padding: 15px; }
}

.footer {
  padding: 15px;
  border-radius: 10px;
  background-color: $footer-color;
  font-size: 90%;
}

div.books {
  width: 100%;
  @include mq($from: smallwidth) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .book {
    flex-grow: 1;
    flex-basis: auto;
    border-radius: 10px;
    margin: 15px;
    @include mq($from: smallwidth) {
      min-width: 330px;
    }
    @include mq($from: 855px) {
      max-width: 50%;
    }
    @include mq($from: 1215px) {
      max-width: 33%;
    }
    //        @include mq($until: smallwidth) {
    //            background: red;
    //        }
  }
}

.prevbooks, .prevshorts, .prevfilms {
  display: block;
  margin: auto;
  padding: 10px;
  background-color: $emphlink-color;
  border-radius: 10px;

  text-align: center;

  a, a:visited, a:hover {
    color: inherit;
  }
}

.bookimg {
  float: left;
  padding-right: 15px;
}

h1.books {
  background-color: $white;
  border-radius: 10px;
  padding: 15px;
  margin: 15px;
  display: inline-block;
}

.book header {
  min-height: 6em;
}

.book header p {
  margin: 0;
}

dt {
  font-family: 'Audiowide', Helvetica, Arial, sans-serif;
  //   font-weight: bold;
  //   font-size: 170%;
}

@import "affiliate_links";
