$bgcolor-default: #ffffff;
$bgcolor-default: linear-gradient(to bottom,  #ffffff 0%, #e5e5e5 100%);

@mixin background($url, $bgsize, $bgcolor: $bgcolor-default) {
  background: url($url) center center no-repeat, $bgcolor;
  background-size: $bgsize, cover;

  &.free {
    background: url("/images/links/free-banner.png") center right no-repeat, url($url) center center no-repeat, $bgcolor;
    background-size: 30px, $bgsize, cover;
  }
}

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

.bookimg { display: table-cell; float: none; }

.affiliate-links {
  display: table-cell;
  list-style-type: none;
  vertical-align: top;
  padding: 15px;
  li {
    margin: 5px;
  }

  &.horizontal {
    li {
      display: inline-block;
    }
  }
}

.info-links {
  list-style-type: none;
  vertical-align: top;
  padding: 5px;
  margin: 0;

  li {
    display: inline-block;
    margin: 5px;
  }
}

%affiliate-link {
  display: block;
  width: 100px;
  height: 25px;

  overflow: hidden;
  text-indent: -2000px;
  padding: 0;
  border: black 1px solid;
  border-radius: 5px;

  @include box-shadow( 3px 4px 5px 0px rgba(0,0,0,0.75) );
}

// ---

.kindle {
  @extend %affiliate-link;
  @include background("/images/links/kindle.png", 90px);
}

.itunes{
  @extend %affiliate-link;
  @include background("/images/links/itunes.png", 80px);
}

.amazon{
  @extend %affiliate-link;
  @include background("/images/links/amazon.png", 90px);
}

.kobo {
  @extend %affiliate-link;
  @include background("/images/links/kobo.png", 50px);
}

.nook {
  @extend %affiliate-link;
  @include background("/images/links/nook.jpg", 88px 31px);
}

.googleplay {
  @extend %affiliate-link;
  @include background("/images/links/google-play.png", 100px);
}

.escapepod {
  @extend %affiliate-link;
  @include background("/images/links/escapepod.png", 100px);
}

.web {
  @extend %affiliate-link;
  @include background("/images/links/webpage.png", 100px);
}

.audio {
  @extend %affiliate-link;
  @include background("/images/links/audio.png", 100px);
}

.youtube {
  @extend %affiliate-link;
  @include background("/images/links/youtube.png", 80px);
}

.netflix {
  @extend %affiliate-link;
  @include background("/images/links/netflix.gif", 80px, #E9231B);
}

.bfiplayer {
  @extend %affiliate-link;
  @include background("/images/links/bfiplayer.png", 100px, #000);
}

// ---

.justwatch {
  @extend %affiliate-link;
  @include background("/images/links/justwatch.png", 85px, rgba(5,30,40,.98));
}

.goodreads {
  @extend %affiliate-link;
  @include background("/images/links/goodreads.jpg", 95px, #ffffff);
}

.isfdb {
  @extend %affiliate-link;
  @include background("/images/links/isfdb.png", 95px, #ffffff);
}

.imdb {
  @extend %affiliate-link;
  @include background("/images/links/imdb.png", 70px, #e6b91e);
}
